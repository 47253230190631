const moduleUiTexts = {
	name: {
		da: 'Navn',
		en: 'Name',
		se: `Namn`,
		de: `Name`,
		pl: `Nazwa`,
		ro: `Nume`,
		ua: `Ім'я`,
	},
	voice: {
		da: 'Stemme',
		en: 'Voice',
		se: `Röst`,
		de: `Stimme`,
		pl: `Głos`,
		ro: `Voce`,
		ua: `Голос`,
	},
	moduleGroup: {
		da: 'Modulgruppe',
		en: 'Module group',
		se: `Grupp av moduler`,
		de: `Modulgruppe`,
		pl: `Grupa modułów`,
		ro: `Grup de module`,
		ua: `Група модулів`,
	},
	background: {
		da: 'Baggrund',
		en: 'Background',
		se: `Bakgrund`,
		de: `Hintergrund`,
		pl: `Tło`,
		ro: `Fond`,
		ua: `Тло`,
	},
	access: {
		da: 'Adgang',
		en: 'Access',
		se: `Tillträde`,
		de: `Zugang`,
		pl: `Dostęp`,
		ro: `Acces`,
		ua: `Доступ`,
	},
	requiredModules: {
		da: 'Krævede moduler',
		en: 'Required modules',
		se: `Nödvändiga moduler`,
		de: `Erforderliche Module`,
		pl: `Wymagane moduły`,
		ro: `Module necesare`,
		ua: `Необхідні модулі`,
	},
	stars: {
		da: 'Stjerner',
		en: 'Stars',
		se: `Stjärnor`,
		de: `Sterne`,
		pl: `Gwiazdki`,
		ro: `Stele`,
		ua: `Зірки`,
	},
	starsMax: {
		da: 'Max',
		en: 'Max',
		se: `Max`,
		de: `Max`,
		pl: `Max`,
		ro: `Max`,
		ua: `Макс`,
	},
	starType: {
		da: 'Type',
		en: 'Type',
		se: `Typ`,
		de: `Art`,
		pl: `Typ`,
		ro: `Tip`,
		ua: `Тип`,
	},
	starsToPass: {
		da: 'Krævet',
		en: 'Required',
		se: `Krävs`,
		de: `Erforderlich`,
		pl: `Wymagane`,
		ro: `Necesar`,
		ua: `Необхідний`,
	},
	starsPerPercentPoints: {
		da: 'Per % point',
		en: 'Per % points',
		se: `Per %-enheter`,
		de: `Pro %-Punkte`,
		pl: `Za punkt procentowy`,
		ro: `Per punct procentual`,
		ua: `За % пунктів`,
	},
	attempts: {
		da: 'Forsøg',
		en: 'Attempts',
		se: `Försök`,
		de: `Versuchen`,
		pl: `Próba`,
		ro: `Încercare`,
		ua: `Спроба`,
	},
	tasks: {
		da: 'Opgaver',
		en: 'Tasks',
		se: `Uppgifter`,
		de: `Aufgaben`,
		pl: `Zadania`,
		ro: `Sarcini`,
		ua: `Завдання`,
	}
};

export {
	moduleUiTexts
};