const companyUiTexts = {
	name: {
		da: `Navn`,
		en: `Name`,
		se: `Namn`,
		de: `Name`,
		pl: `Nazwa`,
		ro: `Nume`,
		ua: `Ім'я`,
	},
	companyId: {
		da: 'Firma-id',
		en: 'Company id',
		se: 'Företags ID',
		de: 'Firmen-ID',
		pl: 'Identyfikator firmy',
		ro: 'ID-ul companiei',
		ua: 'Ідентифікатор компанії',
	},
	url: {
		da: 'URL',
		en: 'URL',
		se: 'URL',
		de: 'URL',
		pl: 'URL',
		ro: 'URL',
		ua: 'URL',
	},
	languages: {
		da: 'Sprog',
		en: 'Languages',
		se: 'Språk',
		de: 'Sprachen',
		pl: 'Języki',
		ro: 'Limbi',
		ua: 'Мови',
	},
	defaultLanguageId: {
		da: 'Default sprog',
		en: 'Default language',
		se: 'Standardspråk',
		de: 'Standardsprache',
		pl: 'Domyślny język',
		ro: 'Limba implicita',
		ua: 'Мова за замовчуванням',
	},
	tenantId: {
		da: 'Tenant-id',
		en: 'Tenant id',
		se: 'Tenant id',
		de: 'Tenant id',
		pl: 'Tenant id',
		ro: 'Tenant id',
		ua: 'Tenant id',
	},
	facilitatorLogin: {
		da: 'Facilitator login',
		en: 'Facilitator login',
		se: 'Facilitatorinloggning',
		de: 'Moderatoren-Login',
		pl: 'Logowanie dla moderatora',
		ro: 'Conectare facilitator',
		ua: 'Вхід фасилітатора',
	},
	playerLogin: {
		da: 'Spiller login',
		en: 'Player login',
		se: 'Spelarinloggning',
		de: 'Spieler-Login',
		pl: 'Logowanie gracza',
		ro: 'Conectare jucător',
		ua: 'Вхід гравця',
	},
	playerLabel: {
		da: 'Spiller-label',
		en: 'Player label',
		se: 'Spelarens etikett',
		de: 'Spielerbezeichnung',
		pl: 'Etykieta gracza',
		ro: 'Eticheta jucătorului',
		ua: 'Мітка гравця',
	},
	loginText: {
		da: 'Login-tekst',
		en: 'Login text',
		se: 'Inloggningstext',
		de: `Anmeldetext`,
		pl: `Tekst logowania`,
		ro: `Text de conectare`,
		ua: `Текст входу`
	},
	apis: {
		da: `API'er`,
		en: 'APIs',
		se: 'API:er',
		de: 'APIs',
		pl: 'Pszczoła',
		ro: 'API-uri',
		ua: 'API',
	},
	admins: {
		da: 'Admins',
		en: 'Admins',
		se: 'Administratörer',
		de: 'Admins',
		pl: 'Administratorzy',
		ro: 'Administratori',
		ua: 'Адміністратори',
	},
	facilitators: {
		da: 'Facilitatorer',
		en: 'Facilitators',
		se: 'Facilitatorer',
		de: 'Moderatoren',
		pl: 'Ułatwiacze',
		ro: 'Facilitatori',
		ua: 'Фасилітатори',
	},
	players: {
		da: 'Spillere',
		en: 'Players',
		se: 'Spelare',
		de: 'Spieler',
		pl: 'Gracze',
		ro: 'Jucători',
		ua: 'Гравці',
	},
	departments: {
		da: 'Afdelinger',
		en: 'Departments',
		se: 'Avdelningar',
		de: 'Bereiche',
		pl: 'Działy',
		ro: 'Departamente',
		ua: 'відділів',
	},
	allDepartments: {
		da: 'Alle %departments%',
		en: 'All %departments%',
		se: 'Alla %departments%',
		de: 'Alle %departments%',
		pl: 'Wszystkie %departments%',
		ro: 'Toate %departments%',
		ua: 'Всі %departments%',
	},
	departmentName: {
		da: `Navn`,
		en: `Name`,
		se: `Namn`,
		de: `Name`,
		pl: `Nazwa`,
		ro: `Nume`,
		ua: `Ім'я`,
	},
	courseGroupName: {
		da: `Navn`,
		en: `Name`,
		se: `Namn`,
		de: `Name`,
		pl: `Nazwa`,
		ro: `Nume`,
		ua: `Ім'я`,
	},
	courses: {
		da: 'Kurser',
		en: 'Courses',
		se: 'Kurser',
		de: 'Kurse',
		pl: 'Kursy',
		ro: 'Cursuri',
		ua: 'Курси',
	},
	course: {
		da: 'Kursus',
		en: 'Course',
		se: 'Kurs',
		de: 'Kurs',
		pl: 'Kurs',
		ro: 'Curs',
		ua: 'курс',
	},
	created: {
		da: 'Oprettet',
		en: 'Created',
		se: 'Skapad',
		de: 'Erstellt',
		pl: 'Utworzony',
		ro: 'Creată',
		ua: 'Створено',
	},
	createdBy: {
		da: 'Oprettet af',
		en: 'Created by',
		se: 'Skapad av',
		de: 'Erstellt von',
		pl: 'Stworzone przez',
		ro: 'Creat de',
		ua: 'Створений',
	},
};

export {
	companyUiTexts
};