const redirectData = [
	{
		fromUrl: 'dcsafetytraining.dk',
		toUrl: 'https://traininggames.dk/dc', 
	},
	{
		fromUrl: 'iffhygienetraining.dk',
		toUrl: 'https://traininggames.dk/iff'
	},
	{
		fromUrl: 'beckhygienetraining.dk',
		toUrl: 'https://traininggames.dk/beck'
	},
	{
		fromUrl: 'harboehygienetraining.dk',
		toUrl: 'https://traininggames.dk/harboe'
	},
	{
		fromUrl: 'ticanhygienetraining.dk',
		toUrl: 'https://traininggames.dk/tican'
	},
	{
		fromUrl: 'almondyhygienetraining.se',
		toUrl: 'https://traininggames.dk/almondy'
	},
	{
		fromUrl: 'kelsenhygienetraining.dk',
		toUrl: 'https://traininggames.dk/kelsen'
	},
	{
		fromUrl: 'kmchygienetraining.dk',
		toUrl: 'https://traininggames.dk/kmc'
	},
	{
		fromUrl: 'bhjhygienetraining.dk',
		toUrl: 'https://traininggames.dk/bhj'
	},
	{
		fromUrl: 'delikahygienetraining.dk',
		toUrl: 'https://traininggames.dk/delika'
	},
	{
		fromUrl: 'siahygienetraining.se',
		toUrl: 'https://traininggames.dk/sia'
	},
	{
		fromUrl: 'siahygienetraining.dk',
		toUrl: 'https://traininggames.dk/sia'
	}
];

export {redirectData};