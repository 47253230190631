import dayjs from 'dayjs';

/**
 * Check if valid e-mail format
 * @param {string} email 
 * @returns 
 */
const checkIfValidEmailFormat = (email) => {
	let isValid = false;
	if (email && email.length > 0) {
		const matches = email.toLowerCase().match(
			// eslint-disable-next-line max-len
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
		if (matches) {
			isValid = true;
		}
	}
	return isValid;
};

/**
 * Convert a string to a valid id
 * @param {string} str
 * @param {*} str 
 */
const convertStringToId = (str) => {
	let id = '';
	if (str.length > 0) {
		/* Convert to lowercase, replace space with '-', remove all invalid characters */
		const trimmmedString = str.toLowerCase().replace(/[ ]+/g, '-').replace(/[^a-z0-9-]+/g, '');
		if (trimmmedString.length > 0) id = trimmmedString;
	}
	return id;
};

/**
 * Format miliseconds as "mm:ss";
 * @param {number} miliseconds 
 * @returns 
 */
const formatMiliseconds = (miliseconds) => {
	let result = '0:00';

	if (miliseconds && miliseconds > 0) {
		const minutes = Math.floor(miliseconds / (60 * 1000.));
		const seconds = Math.floor((miliseconds / 1000.) - minutes * 60);
		result = minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
	}
	return result;
};

/**
 * Format date
 * @param {string} dateStr 
 * @returns 
 */
const formatDate = (dateStr) => {
	let result = '-';

	if (dateStr && dateStr.length > 0) {
		result = dayjs(dateStr).format(getDateFormat());
	}

	return result;
};

/**
 * Get date format
 * @returns {string} dateFormat
 */
const getDateFormat = () => {
	let dateFormat = 'DD/MM-YY';
	return dateFormat;
};

/**
 * Convert string of format "hex" to "Uint8Array"
 * @param {string} textStr 
 * @returns 
 */
const convertHexToUint8Array = (textStr) => {
	return new Uint8Array(textStr.match(/.{1,2}/g).map((byte) => {return parseInt(byte, 16);}));
};

/**
 * Convert first character in string to lowercase
 * @param {string} textStr 
 * @returns 
 */
const convertTextToLowercaseFirst = (textStr) => {
	return textStr.charAt(0).toLowerCase() + textStr.slice(1);
};


export {
	checkIfValidEmailFormat,
	convertStringToId,
	formatMiliseconds,
	formatDate,
	getDateFormat,
	convertHexToUint8Array,
	convertTextToLowercaseFirst
};